<template>
  <div>
    <div class="content">
      <div class="all">
      <div class="SearchBar">
        <el-form :inline="true" class="searchBoxForm">
          <el-form-item label="场站名称" class="searchItem">
            <el-input
              size="small"
              v-model="pageParams.name"
              placeholder="请输入"
              clearable
              class="search"
            ></el-input>
          </el-form-item>
          <el-form-item label="场站分类" class="searchItem">
            <el-select
                v-model="pageParams.stationKind"
                placeholder="请选择"
                size="small"
                clearable
                class="search"
                @change="search"
            >
              <el-option
                  v-for="(item, index) in stationKindList"
                  :key="index"
                  :label="item.name"
                  :value="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属图层" class="searchItem">
              <el-cascader
                    v-model="pageParams.layerId"
                    :options="optionsList"
                    :props="props"
                    placeholder="请选择图层"
                    popper-class="gis-default"
                    style="width:100%"
                     @change="search"
                  >
                    <!-- <template slot-scope="{ data }">
                      <span v-if="data.layerType == 1"
                        ><img src="../../image/tree-folder.png"
                      /></span>
                      <span v-else><img src="../../image/tree-file.png"/></span>
                      <span> {{ data.name }}</span>
                    </template> -->
                  </el-cascader>
           
          </el-form-item>
          <el-form-item label="" class="searchItem">
             <el-button type="primary" @click="search" size="mini" >查询</el-button>
             <el-button type="warning" @click="restSearch" size="mini" >重置</el-button>
             <el-button type="success" @click="exportFile" size="mini" >导出</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="contain">
        <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin-top: 10px ;"
            border
            :header-cell-style="{
							color: '#333',
							fontFamily: 'MicrosoftYaHeiUI',
							fontSize: '14px',
							fontWeight: 900,
							textAlign: 'left',
							background: '#f8f8f9',
						}"
            height="calc(100vh - 340px)"
            :stripe="true"
          >
          <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            align="center"
            width="50"
          >
          </el-table-column>
          <el-table-column
            prop="stationName"
            width="180"
            label="场站名称"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="stationCode"
            label="场站编码"
            width="180"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="stationKindName"
            label="场站类型"
            width="100"
            show-overflow-tooltip
          >
          </el-table-column>
          <!-- <el-table-column
            prop="scale"
            label="设计规模(Nm³/h)"
            show-overflow-tooltip
          >
          </el-table-column> -->
          <el-table-column
            prop="address"
            label="位置"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="createBy"
            label="操作人"
            width="80"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="操作时间"
            width="140"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="layerName"
            label="所属图层"
            width="140"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column label="操作" align="left" width="150">
            <template slot-scope="{row}">
              <el-button type="text" @click="getDetail(row)" size="mini">查看</el-button>
              <el-button type="text" style="color: #FF8610" @click="deleteById(row.id,row.stationName)" size="mini">删除</el-button>
              <el-button type="text" style="color: #0A8E78" @click="setPosition(row)" size="mini">定位</el-button>
              <!-- <el-button type="text" @click="getVideoList(row)" size="mini">视频监控</el-button> -->
            </template> 
          </el-table-column>
        </el-table>
      <div class="total">
        <el-pagination
        background
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageParams.current"
        :page-sizes="[30, 50, 100]"
        :page-size="pageParams.size"
      >
      </el-pagination>
      </div>
    </div>
    </div>
    <station-info ref="stationInfo"></station-info>
    <station-video ref="stationVideo"></station-video>
    <station-location ref="stationLocation"></station-location>
  </div>
</template>

<script>
import '../Gis/style/skin.scss'
import pageMixins from "@/utils/pageMixins"
import stationInfo from './stationInfo'
import stationVideo from './stationVideo'
import stationLocation from './stationLocation'
import { exportMethod } from '../../common/js/exportExcel'
import {deleteStationById, exportStandingBook, pageStandingBook} from "../../RequestPort/gisbook"
import { commonDict, constantDict } from '../Gis/utils/dict'
import { stationFindByIds } from '../Gis/apis/tool'
import { findDictTree } from '../Gis/apis/commonType'
import coverlayer from '../Gis/apis/coverlayer'
export default {
  name: 'StationList',
  components: {
    stationInfo,
    stationVideo,
    stationLocation
  },
  mixins: [pageMixins],
  data() {
    return {
      stationKindList:[],
      pageParams: {
        stationType:constantDict.gongqizhan.dictCode,
        stationKind:'',
        name:'',
        current: 1,
        size: 100,
      }, //  分页查询参数
      total:null,
      tableData: [], //  表格数据
      optionsList:[],
      props: {
        emitPath: false,
        label: 'name',
        value: 'id'
      },
    };
  },
  mounted() {
    this.loadList(this.pageParams)
    this.getCodeName()
  },
  methods: {
   async getCodeName() {
       let p = { sysType: 1}
        let res = await coverlayer.getFindTree(p)
        this.optionsList = res.data
      let code = commonDict[100019].code
      findDictTree({ dictCode: code }).then(res => {
        if (res.data) {
          this.stationKindList = res.data[commonDict[100019].code]
        }
      })
    },
    exportFile(){
      exportStandingBook(this.pageParams).then(res=>{
        exportMethod(res,'场站台账报表')
      })
    },
    search() {
      this.pageParams.current = 1;
      this.loadList(this.pageParams);
    },
    restSearch() {
      this.pageParams = {
        stationType:constantDict.gongqizhan.dictCode,
        stationKind:'',
        name:'',
        current: 1,
        size: 100,
      }
      this.loadList(this.pageParams)
    },
    loadList(obj){
      pageStandingBook(obj).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    handleCurrentChange(current) {
      this.pageParams.current = current;
      this.loadList(this.pageParams);
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.handleCurrentChange(1);
    },
    deleteById(id,stationName){
      this.$confirm(`是否确认删除【${stationName}】场站信息？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        deleteStationById({id}).then(res=>{
          if(res.code==200){ 
            this.$message.success(res.msg)
          }else{ 
            this.$message.error(res.msg)
          }
          this.loadList(this.pageParams)
        })
      });
    },
    getDetail(row){
      stationFindByIds({ id: row.id }).then(res => {
        let obj = {
          geometryType: null,
          equipmentType: 'station',
          ...res.data
        }
        obj.point = row.pointArray[0][0]+' '+row.pointArray[0][1]
        this.$refs.stationInfo.openDialog(obj, row.pointArray)
      })
    },
    setPosition(row){
       let data = {}
      stationFindByIds({ id: row.id }).then(res => {
        data = res.data;
        data.point = row.pointArray[0][0]+' '+row.pointArray[0][1]
        data.showType = 'station'
        data.title="供气站"
         this.$refs.stationLocation.openDialog(data,row.point)
      })
    
    },
    // 视频监控
    getVideoList(row){
      this.$refs.stationVideo.openDialog(row)
    }
  },
};
</script>

<style lang="scss" scoped>
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
  }
}
.tableTool{    
  height: 25px;
}
.searchItem {
  margin-bottom: 1%;
  margin-left: 1%;
}
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}
.SearchBar {
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.all {
  background-color:#f3f4f8;height:auto
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  overflow:auto;
  height: calc(100vh - 120px);
}
.search{
  width: 215px;
}
.contain{
  height: 690px;
}
.total{
  margin-left: 15px;
  margin-top: 10px;
}
.table{
  overflow:auto;
  height: 520px;
}
</style>